/* Absolute Center Spinner */
.sidelay {
  z-index: 999;
  height: 70vh;
  width: 90vw;
  margin: auto;
  display: flex;
  justify-content: center;
  text-align: center;
}

/* spinner  */

.loader {
  margin: auto auto;
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
}
.loader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: rgb(0, 0, 0);
  -webkit-animation: loader3 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
}
.loader span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}
@keyframes loader {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes loader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}
