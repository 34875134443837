@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,900,700,500,300,100);

::selection {
  background: #f31544;
  color: #fff;
}
::moz-selection {
  background: #f31544;
  color: #fff;
}
.clearfix::after {
  content: '';
  clear: both;
  display: table;
}
.col-left {
  float: left;
}
.col-right {
  float: right;
}
h1 {
  font-size: 1.5em;
  color: #444;
}
h2 {
  font-size: 0.9em;
}
h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}
p {
  font-size: 0.75em;
  color: #666;
  line-height: 1.2em;
}
a {
  text-decoration: none;
  color: #00a63f;
}

#invoiceholder {
  width: 100%;
  height: 100%;
  padding: 50px 10px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
}
#invoice {
  position: relative;
  margin: 0 auto;
  width: 700px;
  background: #fff;
}

[id*='invoice-'] {
  /* Targets all id with 'col-' */
  /*  border-bottom: 1px solid #EEE;*/
  padding: 20px;
}

#invoice-top {
  border-bottom: 2px solid #00a63f;
}
#invoice-mid {
  min-height: 110px;
}
#invoice-bot {
  min-height: 240px;
}

.logo {
  display: inline-block;
  /* vertical-align: middle; */
  overflow: hidden;
  font-weight: 700;
  font-size: 18px;
  float: left;
  margin-top: -1em;
}
.info {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
.logo img,
.clientlogo img {
  width: 100%;
}
.clientlogo {
  display: inline-block;
  vertical-align: middle;
  width: 50px;
}
.clientinfo {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
.title {
  float: right;
  margin-top: -1em;
}
.title p {
  text-align: right;
}
#message {
  margin-bottom: 30px;
  display: block;
}
h2 {
  margin-bottom: 5px;
  color: #444;
}
.col-right td {
  color: #666;
  padding: 5px 8px;
  border: 0;
  font-size: 0.75em;
  border-bottom: 1px solid #eeeeee;
}
.col-right td label {
  margin-left: 5px;
  font-weight: 600;
  color: #444;
}
.cta-group a {
  display: inline-block;
  padding: 7px;
  border-radius: 4px;
  background: rgb(29, 27, 27);
  margin-right: 10px;
  min-width: 100px;
  text-align: center;
  color: #fff;
  font-size: 0.75em;
}
.cta-group .btn-primary {
  background: #00a63f;
}
.cta-group.mobile-btn-group {
  display: none;
}
table {
  width: 100%;
  border-collapse: collapse;
}
td {
  padding: 10px;
  border-bottom: 1px solid #cccaca;
  font-size: 0.7em;
  text-align: left;
}

.tabletitle th {
  border-bottom: 2px solid #ddd;
  text-align: right;
}
.tabletitle th:nth-child(2) {
  text-align: left;
}
th {
  font-size: 0.7em;
  text-align: left;
  padding: 5px 10px;
}
.item {
  width: 50%;
}
.list-item td {
  text-align: right;
}
.list-item td:nth-child(2) {
  text-align: left;
}
.total-row th,
.total-row td {
  text-align: right;
  font-weight: 700;
  font-size: 0.75em;
  border: 0 none;
}
.table-main {
}
footer {
  border-top: 1px solid #eeeeee;
  padding: 15px 20px;
}
.effect2 {
  position: relative;
}
/* .effect2:before,
.effect2:after {
  z-index: -1;
  position: absolute;
  content: '';
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.effect2:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
} */
@media screen and (max-width: 450px) {
  .logo {
    float: none;
    vertical-align: middle !important;
    padding: 1em;
    margin-left: -5px !important;
  }
  .title {
    padding: 1em;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 0.9em;
  }
  #invoice {
    width: 100%;
  }
  #message {
    margin-bottom: 20px;
  }
  [id*='invoice-'] {
    padding: 20px 10px;
  }
  .logo {
    width: 140px;
  }
  .title {
    float: none;
    display: inline-block;
    vertical-align: middle;
    margin-left: 40px;
  }
  .title p {
    text-align: left;
  }
  .col-left,
  .col-right {
    width: 100%;
  }
  .table {
    margin-top: 20px;
  }
  #table {
    white-space: nowrap;
    overflow: auto;
  }
  td {
    white-space: normal;
  }
  .cta-group {
    text-align: center;
  }
  .cta-group.mobile-btn-group {
    display: block;
    margin-bottom: 20px;
  }
  /*==================== Table ====================*/
  .table-main {
    border: 0 none;
  }
  .table-main thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .table-main tr {
    border-bottom: 2px solid #eee;
    display: block;
    margin-bottom: 20px;
  }
  .table-main td {
    font-weight: 700;
    display: block;
    padding-left: 40%;
    max-width: none;
    position: relative;
    border: 1px solid #cccaca;
    text-align: left;
  }
  .table-main td:before {
    /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
    content: attr(data-label);
    position: absolute;
    left: 10px;
    font-weight: normal;
    text-transform: uppercase;
  }
  .total-row th {
    display: none;
  }
  .total-row td {
    text-align: left;
  }
  footer {
    text-align: center;
  }
}

.more {
  float: right;
  font-size: 12px;
  color: royalblue;
}

@media screen {
  .screen_for_print {
    display: none;
  }
}
