#mainFooter .footer-top {
  padding: 100px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  width: 100%;
  background-color: white !important;
}

@media (max-width: 767px) {
  #mainFooter .footer-top {
    padding: 60px 0 30px;
  }
}

#mainFooter .footer-top .footer-top-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 767px) {
  #mainFooter .footer-top .footer-top-inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  #mainFooter .footer-top .footer-top-inner .footer-top-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }
}

#mainFooter .footer-top .footer-top-inner .footer-top-1 p {
  font-size: 20px;
  color: #333339;
  line-height: 28px;
  font-family: 'Sentinel Light';
  margin-left: 4em !important;
}

#mainFooter .footer-top .footer-top-inner .footer-top-1 .footer-logo {
  margin-bottom: 30px;
}

#mainFooter .footer-top .footer-top-inner .footer-top-1 .footer-logo a {
  color: #121212;
  text-transform: uppercase;
  font-size: 20px;
  font-family: 'Adobe Caslon Pro Bold';
  text-transform: uppercase;
  letter-spacing: 2px;
}

#mainFooter .footer-top-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 767px) {
  #mainFooter .footer-top-2 {
    width: 100%;
  }
}

#mainFooter .footer-top-2 .footer-top-2-col-1 {
  width: 140px;
}

#mainFooter .footer-top-2 h4 {
  font-size: 12px;
  color: #ccc;
  font-family: 'Gotham Medium';
  margin-bottom: 10px;
}

#mainFooter .footer-top-2 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#mainFooter .footer-top-2 ul li {
  margin-bottom: 10px;
}

#mainFooter .footer-top-2 ul li a {
  color: #666;
  font-size: 12px;
  font-family: 'Gotham Book';
}

#mainFooter .footer-bottom {
  border-top: 1px solid #eaeaea;
  padding: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-left: 11em !important;
}

#mainFooter .footer-bottom p {
  margin-bottom: 0;
  font-size: 12px;
  color: #888;
  font-family: 'Gotham Medium';
  letter-spacing: 0.25px;
}

#mainFooter .footer-bottom p a {
  font-size: 12px;
  color: #888;
  font-family: 'Gotham Medium';
  letter-spacing: 0.25px;
  padding: 0 12px;
}

.footer-top-2-col-1 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
@media (max-width: 580px) {
  .footer-top-2-col-1 {
    text-align: center;
  }
  .footer-top-2-col-2 {
    text-align: center;
  }
  .footer-top-2-col-3 {
    text-align: center;
  }
}
.footer-top-2-col-1 h4 {
  font-size: 12px;
  color: rgb(138, 137, 137);
  font-family: 'Gotham Medium';
  margin-bottom: 10px;
  font-weight: 700;
}
.footer-top-2-col-1 ul li a {
  color: #666;
  font-size: 12px;
  font-family: 'Gotham Book';
}
.footer-top-2-col-2 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-top-2-col-2 h4 {
  font-size: 12px;
  color: rgb(138, 137, 137);
  font-family: 'Gotham Medium';
  margin-bottom: 10px;
  font-weight: 700;
}
.footer-top-2-col-2 ul li a {
  color: #666;
  font-size: 12px;
  font-family: 'Gotham Book';
}
.footer-top-2-col-3 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-top-2-col-3 h4 {
  font-size: 12px;
  color: rgb(138, 137, 137);
  font-family: 'Gotham Medium';
  margin-bottom: 10px;
  font-weight: 700;
}
.footer-top-2-col-2 ul li a {
  color: #666;
  font-size: 12px;
  font-family: 'Gotham Book';
}

.icon_label {
  color: rgb(114, 113, 113);
  font-size: 12px;
  margin-left: 6px;
}
