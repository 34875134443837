.collapsible {
  cursor: pointer;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.content {
  /* padding: 0 18px; */
  overflow: hidden;
  background-color: #ffffff;
}

.buy_button {
  cursor: pointer;
  background: green;
  padding: 0.2em 0.8em;
  margin: 4px;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  font-family: sans-serif;
}

.variant_table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

.variant_table_td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
}

.variant_table_th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.variant_table_tr:nth-child(even) {
  background-color: #dddddd73;
}

.variant_table_quantity {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  background-color: white;
  padding: 4px;
  outline: none;
}

.collapse {
  display: none;
}

.variant_table2 {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

.variant_table2_td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
}

.variant_table2_th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.serial_number_span {
  background-color: #d3d3d3;
  padding: 4px;
  border-radius: 2px;
}

.error-block {
  background-color: #f8d7da;
  color: #7a2124;
  padding: 8px;
  border: 1px solid #7a212436;
  border-radius: 4px;
  font-size: 10px;
}
.demo-option-label-item {
  margin-right: 6px;
}
.anticon-check {
  color: black;
}
[aria-selected='true'] {
  background-color: #f5f5f5 !important;
}
